import './App.scss';
import Loader from './Pages/Loader';
import React, { Suspense } from 'react';
import logoIcon from './Assets/logo_1.png';
import { useSelector } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { selectLoading } from './Redux/slice';
import { Route, Routes } from 'react-router-dom';

const PaymentPage = React.lazy(() => import('./Pages/Payment'));
const FailurePage = React.lazy(() => import('./Pages/Failure'));
const SuccessPage = React.lazy(() => import('./Pages/Success'));
const AuthenticationPage = React.lazy(() => import('./Pages/Login'));

const App: React.FC = () => {

  const loading = useSelector(selectLoading);

  return (
    <div className='mainDiv'>
      <Loader isLoading={loading}/>
      <Suspense fallback={<FallBack />}>
        <Routes>
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/failure" element={<FailurePage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/login/:token" element={<AuthenticationPage />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;

export const FallBack: React.FC = () => {
  const [initialWidth, setInitialWidth] = React.useState<number>(0);

  React.useEffect(() => {
    getWidth();
    // eslint-disable-next-line
  }, []);

  const getWidth = () => {
    setInitialWidth((prevWidth) => prevWidth + 3);
  };

  React.useEffect(() => {
    if (initialWidth < 90) {
      const timer = setTimeout(() => setInitialWidth((prevWidth) => prevWidth + 5), 500);
      return () => clearTimeout(timer);
    }
  }, [initialWidth]);

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div>
        <img src={logoIcon} style={{ width: '200px', height: '100%' }} alt="logo" />
        <ProgressBar style={{ marginTop: 8 }} now={initialWidth} variant="info" />
      </div>
    </div>
  );
};
