import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

interface MainState {
    loading: boolean;
    token: string | null;
    reference: string | null;
    balance: string | null;
    name: string | null;
    email: string | null;
    redirectURL: string | null;
    status: 'idle' | 'SUCCESS' | 'FAILURE' | 'PROCESSING'| 'IN_PROGRESS';
    error: string | null;
    currency: string | null;
    transactionAmount: string | null;
    paymentUrl: string | null;
    userName: string;
}

interface LoginData {
    username: string;
    password: string;
    token: string;
}

interface PaymentData {
    token: string;
}

interface TokenData {
    version: string;
    amount: number;
    cartId: string;
    currency: string;
    callbackUrl: string;
    returnUrl: string;
    notes: string;
    authToken: string;
}

interface AuthActionPayload {
    actionType: 'login' | 'payment' | 'consent' | 'getToken';
    data: LoginData | PaymentData | TokenData ;
}

const ApiUrl = (actionType: AuthActionPayload['actionType']) => {
    if(actionType === 'login') {
        return 'payin/login';
    } else if(actionType === 'payment') {
        return 'payin';
    }  else if(actionType === 'consent') {
        return 'payin/consent-token';
    }  else if(actionType === 'getToken') {
        return 'payin/request-token';
    }  else {
        return '';
    }
}

export const handleAuthAction = createAsyncThunk('auth/handleAction', async ({ actionType, data }: AuthActionPayload) => {
        const endpoint = ApiUrl(actionType);
        const baseUrl = (window.location.origin.indexOf('localhost') >= 0) ? 'http://3.110.90.222:8082/api/v1/' :  `${window.location.origin}/api/v1/`
        const response = await fetch(baseUrl + endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error('Transaction Failed');
        }

        return response.json();
    }
);

const initialState: MainState = {
    loading: false,
    token: null,
    reference: null,
    balance: null,
    name: null,
    email: null,
    redirectURL: null,
    status: 'idle',
    error: null,
    currency: null,
    transactionAmount: null,
    paymentUrl: null,
    userName: ''
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setTokenFromUrl(state, action: PayloadAction<string>) {
            state.token = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(handleAuthAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(handleAuthAction.fulfilled, (state, action: any) => {
                state.loading = false;
                if(action.payload.status === 'FAILURE' || action.payload.status === 'SUCCESS') {
                    state.status = action.payload.status;
                    return
                }
                if (action?.meta?.arg.actionType === 'login') {
                    const { reference, balance, name, email, amount } = action.payload;
                    state.reference = reference;
                    state.balance = balance;
                    state.name = name;
                    state.email = email;
                    state.status = 'idle';
                    state.transactionAmount = amount;
                } else if (action?.meta?.arg.actionType === 'consent')  {
                    state.status = 'idle';
                    state.reference = action.payload.reference;
                    state.currency = action.payload.currency;
                    state.transactionAmount = action.payload.amount;
                    state.redirectURL = action?.payload?.redirectURL ?? 'https://www.google.co.in/';
                } else if (action?.meta?.arg.actionType === 'getToken')  {
                    state.status = 'idle';
                    state.paymentUrl = action.payload.paymentUrl;
                    state.userName = action.payload.userName;
                    state.reference =  action.payload.reference;
                    state.redirectURL = action?.payload?.redirectURL ?? '';
                }
            })
            .addCase(handleAuthAction.rejected, (state, action) => {
                state.loading = false;
                state.status = 'FAILURE';
                state.error = action?.error?.message ?? 'Transaction Failed';
            });
    },
});

export const { setTokenFromUrl } = commonSlice.actions;

export const selectToken = (state: { common: MainState }) => state.common.token;
export const selectLoading = (state: { common: MainState }) => state.common.loading;
export const selectStatus = (state: { common: MainState }) => state.common.status;
export const selectError = (state: { common: MainState }) => state.common.error;
export const selectRedirectUrl = (state: { common: MainState }) => state.common.redirectURL;
export const selectUserDetails = (state: { common: MainState }) => ({
    reference: state.common.reference,
    balance: state.common.balance,
    name: state.common.name,
    email: state.common.email,
    currency: state.common.currency,
    transactionAmount: state.common.transactionAmount,
});

export const selectTokenDetails = (state: { common: MainState }) => ({
    reference: state.common.reference,
    paymentUrl: state.common.paymentUrl,
    userName: state.common.userName,
});

export default commonSlice.reducer;
